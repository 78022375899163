






















































import Base from '@/mixins/Base.vue';
import { INotification, INotificationResponse } from '@/interfaces/notification';

const component = Base.extend({
    computed: {
        notifications() {
            return this.$store.getters.notifications;
        },
    },
    data() {
        return {
            active: false,
            notification: undefined as any | INotification,
        };
    },
    mounted() {
        this.active = true;
        this.getNotification();
    },
    methods: {
        getNotification(): void {
            this.get<INotificationResponse>(`notifications/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.notification = data.data;
                });
        },
        markNotificationAsRead(): void {
            this.put<INotificationResponse>(`notifications/${this.notification.id}/read`)
                .then(() => {
                    this.$router.push({ name: 'notifications.list' });
                    this.$emit('reload');
                    const notif: INotification = this.notifications.find((n: INotification) => n.id === this.notification.id);
                    if (notif) {
                        notif.is_read = true;
                    }
                });
        },
        markNotificationAsUnread(): void {
            this.put<INotificationResponse>(`notifications/${this.notification.id}/unread`)
                .then(() => {
                    this.$router.push({ name: 'notifications.list' });
                    this.$emit('reload');
                    const notif: INotification = this.notifications.find((n: INotification) => n.id === this.notification.id);
                    if (notif) {
                        notif.is_read = false;
                    }
                });
        },
    },
});

export default component;
